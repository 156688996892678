
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { GetStaticPropsContext } from 'next';
import Service from 'lib/cms/service';
import { getNotifications } from 'lib/notification';
import { getPromotionPopup } from 'lib/promotionpopup';
import Page, { PageProps } from 'components/Page';
import { Page as IPage } from 'interfaces/cms/page';
import { Preview } from 'components/common/Preview';
function HomePage({ page, hrefLang, previewMode }: PageProps) {
    return (<>
      <Preview previewMode={Boolean(previewMode)}/>
      <Page page={page} hrefLang={hrefLang}/>
    </>);
}
async function getStaticProps({ locale, locales, preview, }: GetStaticPropsContext) {
    if (!locale || !locales)
        return;
    const strapi = new Service();
    const [layout, settings, productTypes] = await Promise.all([
        strapi.getLayout(locale),
        strapi.getSettings(locale),
        strapi.getProductTypes(locale),
    ]);
    const previewMode = preview ? true : null;
    const getHrefLang = async () => {
        const results = await Promise.all(locales.map(async (item) => {
            const lang = await strapi.getHomePageByLocale(item);
            return lang?.id;
        }));
        return locales.filter((_v, index) => results[index]);
    };
    const hrefLang = await getHrefLang();
    let page: IPage | null;
    if (previewMode) {
        page = await strapi.getHomePagePreviewByLocale(locale);
    }
    else {
        page = await strapi.getHomePageByLocale(locale);
    }
    if (!layout || !page) {
        return {
            notFound: true,
            revalidate: process.env.REVALIDATE_MS
                ? parseInt(process.env.REVALIDATE_MS)
                : 60,
        };
    }
    return {
        props: {
            locale,
            layout,
            notifications: getNotifications(layout, page),
            popup: getPromotionPopup(layout, page),
            settings,
            page,
            isMultipleLang: true,
            productTypes,
            previewMode,
            hrefLang,
        },
        revalidate: process.env.REVALIDATE_MS
            ? parseInt(process.env.REVALIDATE_MS)
            : 60,
    };
}
export default HomePage;

    async function __Next_Translate__getStaticProps__191ff520a94__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191ff520a94__ as getStaticProps }
  